function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { FieldType, getTimeField, MISSING_VALUE } from '@grafana/data';

function readStacks(root, options, instanceName, count) {
  var curNode = root;
  var stacks = instanceName.split(/[\n,]/);

  var _iterator = _createForOfIteratorHelper(stacks),
      _step;

  try {
    var _loop = function _loop() {
      var stackFrame = _step.value;
      stackFrame = stackFrame.trim();

      if (!stackFrame || options.hideUnresolvedStackFrames && stackFrame.startsWith('0x')) {
        return "continue";
      }

      if (options.hideIdleStacks && stackFrame.startsWith('cpuidle_enter_state+')) {
        return {
          v: void 0
        };
      }

      var child = curNode.children.find(function (child) {
        return child.name === stackFrame;
      });

      if (!child) {
        child = {
          name: stackFrame,
          value: 0,
          children: []
        };
        curNode.children.push(child);
      }

      curNode = child;
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _ret = _loop();

      if (_ret === "continue") continue;
      if (_typeof(_ret) === "object") return _ret.v;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  curNode.value = count;
}

export function generateFlameGraphModel(panelData, options) {
  var _a, _b, _c, _d;

  var model = {
    root: {
      name: 'root',
      value: 0,
      children: []
    },
    minDate: 0,
    maxDate: 0
  };

  if (panelData.series.length !== 1) {
    return model;
  }

  var dataFrame = panelData.series[0];

  var _getTimeField = getTimeField(dataFrame),
      timeField = _getTimeField.timeField;

  if (!timeField || dataFrame.length === 0) {
    return model;
  }

  model.minDate = timeField.values.get(0);
  model.maxDate = timeField.values.get(timeField.values.length - 1);

  var _iterator2 = _createForOfIteratorHelper(dataFrame.fields),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var field = _step2.value;

      if (field.type !== FieldType.number || !((_b = (_a = field.config.custom) === null || _a === void 0 ? void 0 : _a.instance) === null || _b === void 0 ? void 0 : _b.name)) {
        continue;
      } // each dataframe (stack) is a rate-converted counter
      // sum all rates in the selected time frame


      var count = 0;

      for (var i = 0; i < field.values.length; i++) {
        var value = field.values.get(i);

        if (value !== MISSING_VALUE) {
          count += value;
        }
      }

      if (count < options.minSamples) {
        continue;
      }

      readStacks(model.root, options, (_d = (_c = field.config.custom) === null || _c === void 0 ? void 0 : _c.instance) === null || _d === void 0 ? void 0 : _d.name, count);
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return model;
}