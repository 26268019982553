import { PanelPlugin } from '@grafana/data';
import { FlameGraphPanel } from './FlameGraphPanel';
import { defaults } from './types';
export var plugin = new PanelPlugin(FlameGraphPanel).setPanelOptions(function (builder) {
  return builder.addNumberInput({
    path: 'minSamples',
    name: 'Min samples',
    defaultValue: defaults.minSamples
  }).addBooleanSwitch({
    path: 'hideUnresolvedStackFrames',
    name: 'Hide unresolved',
    defaultValue: defaults.hideUnresolvedStackFrames
  }).addBooleanSwitch({
    path: 'hideIdleStacks',
    name: 'Hide idle stacks',
    defaultValue: defaults.hideIdleStacks
  });
});